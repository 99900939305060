$(function(){
$(".historyArea .hide").hide();
$(".acc_btn").on('click', function() {
$(this).fadeOut();
$(".historyArea .hide").slideDown();
});


$(".sideArea li a").on("click",  function() {
    if( $("[href^='#']") ){
    $(".sideArea li").removeClass('current');
    $(this).parent().addClass('current');
    const speed = 600;
    let href = $(this).attr("href");
    let target = $(href == "#" || href == "" ? "html" : href);
    let position = target.offset().top;
    $("body,html").animate({ scrollTop: position }, speed, "swing");
    return false;
     }
    });
});
