
//loading
$(window).on('load', function () {

  $(".loading_txt").addClass('fadeInUp');
  $("#loading").delay(2400).fadeOut('slow');

});

// スクロールで要素が表示された時にclassを付与する
function add_class_in_scrolling(target) {
    var winScroll = $(window).scrollTop();
    var winHeight = $(window).height();
    var scrollPos = winScroll + winHeight;

    if(target.offset().top < scrollPos) {
        target.addClass('on');
    }
}

//スクロール連動アニメーション
$(window).on('load scroll', function() {

  if($('.contentWrap').length) {
    add_class_in_scrolling($('.ito01'));
    add_class_in_scrolling($('.ito02'));
    add_class_in_scrolling($('.ito03'));
  }

});

