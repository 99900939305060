import { gsap } from 'gsap';
import gsapCore from 'gsap/gsap-core';
import Scrolltrigger from 'gsap/ScrollTrigger';
gsap.registerPlugin(Scrolltrigger);
gsap.config({ nullTargetWarn: false, });

let menuFlg = true;
let contactFlg = true;
$(document).on('click', '.menu-btn', function () {
  let $this = $(this);
  let $tar = $this.next();
  $this.stop().toggleClass('open');
  if (menuFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.5
    });

    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    menuFlg = false;
    if (contactFlg == false) {
      gsap.to('.navArea', {
        autoAlpha: 0,
        duration: 0.5
      });
      contactFlg = true;

    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.5
    });
  
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    menuFlg = true;
  }
  return false;
});
/*$(document).on('click', '.gNav > ul > li > a', function () {
  let $this = $(this);
  let $tar = $this.next();
  if (contactFlg) {
    gsap.to($tar, {
      autoAlpha: 1,
      duration: 0.5
    });
    $('body').addClass('stopscroll');
    $('html').addClass('stopscroll');
    contactFlg = false;

    if (menuFlg == false) {
      gsap.to('.nav_cnt', {
        autoAlpha: 0,
        duration: 0.5
      });
      //$('.menu-btn').find('.word').text('MENU');
      $this.stop().toggleClass('open');
      menuFlg = true;
    }

  } else {

    gsap.to($tar, {
      autoAlpha: 0,
      duration: 0.5
    });
    $('body').removeClass('stopscroll');
    $('html').removeClass('stopscroll');
    contactFlg = true;

  }
  return false;
});*/



